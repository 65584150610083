import { Component, OnInit } from '@angular/core';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private translate: TranslateService) {
      translate.setDefaultLang("en");
      translate.use("en");
    }

  ngOnInit(): void {
    this.translate.addLangs(["en", "es"]);
    this.translate.setDefaultLang("en");

    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|es/) ? browserLang : "en");
  }

}

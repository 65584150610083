import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing";

import { AppComponent } from "./app.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { FooterComponent } from "./shared/footer/footer.component";

import { ComponentsModule } from "./components/components.module";
import { ExamplesModule } from "./examples/examples.module";
// import { JodDetailComponent } from './jod-detail/jod-detail.component';

// import { NgImageSliderModule } from 'ng-image-slider';
import { HttpClientModule } from "@angular/common/http";

import { PhoneMaskDirective } from "./phone-mask.directive";
import { PhoneFormatPipe } from "./phone-format.pipe";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
// import { TranslateHttpLoader } from "@ngx-translate/http-loader/public_api";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MaintenanceComponent } from './shared/maintenance/maintenance.component';
import { ProductsComponent } from './examples/products/products.component';
// import { Component, ChangeDetectorRef } from '@angular/core';


export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    AppComponent,
    PhoneMaskDirective,
    PhoneFormatPipe,
    MaintenanceComponent,
    // ProductsComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    ExamplesModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    // NgImageSliderModule

    //Translation
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,

        useFactory: httpTranslateLoader,

        deps: [HttpClient],
      },
    }),
  ],
  exports: [PhoneMaskDirective],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

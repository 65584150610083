import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { CareerComponent } from './career/career.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { BlogComponent } from './blog/blog.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';
import { JobDetailAComponent } from './job-detail-a/job-detail-a.component';
import { JobDetailBComponent } from './job-detail-b/job-detail-b.component';
import { ProductsComponent } from './products/products.component';

import { TranslateModule } from '@ngx-translate/core';       // Add


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    declarations: [
        LandingComponent,
        SignupComponent,
        ProfileComponent,
        CareerComponent,
        AboutUsComponent,
        ContactUsComponent,
        BlogComponent,
        MakePaymentComponent,
        ReferFriendComponent,
        JobDetailAComponent,
        JobDetailBComponent,
        ProductsComponent,
    ]
})
export class ExamplesModule { }

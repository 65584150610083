// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class ApiSettingService {

//   constructor() { }
// }

class Constants {
  public BASE_URL: string;
  public BASE_URL_: string;
}

export const CONSTANTS: Constants = {
  BASE_URL: "https://cincoautoinsurance.com/Email_Working",
  BASE_URL_: "https://appcontrol.socialtechlogic.com/api",
  
}
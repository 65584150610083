import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { SignupComponent } from './examples/signup/signup.component';
import { LandingComponent } from './examples/landing/landing.component';
import { CareerComponent } from './examples/career/career.component';
import { JobDetailAComponent } from './examples/job-detail-a/job-detail-a.component';
import { JobDetailBComponent } from './examples/job-detail-b/job-detail-b.component';
import { MakePaymentComponent } from './examples/make-payment/make-payment.component';
import { ReferFriendComponent } from './examples/refer-friend/refer-friend.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { AboutUsComponent } from './examples/about-us/about-us.component';
import { ContactUsComponent } from './examples/contact-us/contact-us.component';
import { BlogComponent } from './examples/blog/blog.component';
import { ProductsComponent } from './examples/products/products.component';
// import { MaintenanceComponent } from './shared/maintenance/maintenance.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: ComponentsComponent },
  { path: 'user-profile', component: ProfileComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'getquote', component: LandingComponent },
  { path: 'career', component: CareerComponent },
  { path: 'career/social-media-influencer', component: JobDetailAComponent },
  { path: 'career/digital-marketing-specialist', component: JobDetailBComponent },
  { path: 'make-payment', component: MakePaymentComponent },
  { path: 'refer-friend', component: ReferFriendComponent },
  // {path: '', redirectTo: 'products', pathMatch: 'full'},

  { path: 'products', component: ProductsComponent },
  // { path: 'products#:id', component: ProductsComponent },
  // { path: 'products#form-generic', component: ProductsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'nucleoicons', component: NucleoiconsComponent },
  // { path: '', redirectTo: 'maintenance', pathMatch: 'full' },
  // { path: 'maintenance', component: MaintenanceComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    // RouterModule.forRoot(routes)

    RouterModule.forRoot(routes, {
      useHash: false,anchorScrolling: 'enabled',onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from "@angular/router";
import { FormApiService } from '../../shared/services/form-api.service';

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.css']
})
export class ReferFriendComponent implements OnInit {

  Form = new FormGroup({
    referral_name: new FormControl(),
    referral_email: new FormControl(),
    referral_mobile: new FormControl(),
    email: new FormControl(),
    name: new FormControl(),
  });

  returnUrl: string;

  constructor(private formBuilder: FormBuilder, private calendar: NgbCalendar,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private http: HttpClient, private route: ActivatedRoute, private router: Router,
    private renderer: Renderer2, el: ElementRef,
    private _FormApiService: FormApiService,
  ) {

  }

  ngOnInit(): void {

  }

  Submit(){
    console.log("FORM");

    var obj = {
      cinco_subject: "Refer a Friend Form",
      referralFullName: this.Form.get('referral_name').value,
      referralEmail: this.Form.get('referral_email').value,
      referralMobileNumber: this.Form.get('referral_mobile').value,
      Email: this.Form.get('email').value,
      FullName: this.Form.get('name').value,
    }
   // console.log(obj);

    this._FormApiService.insertReferralFormData(obj)
        .subscribe(response => {
         // console.log(response);
         alert('Form has been submited.');
         this.Form.get('referral_name').reset()
         this.Form.get('referral_email').reset()
         this.Form.get('referral_mobile').reset()
         this.Form.get('email').reset()
         this.Form.get('name').reset()

        });
  }

}

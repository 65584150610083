import { Component, OnInit, ElementRef } from "@angular/core";
import { FormApiService } from '../../shared/services/form-api.service';
import { ActivatedRoute, Router } from '@angular/router';

import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  

  private toggleButton: any;
  private sidebarVisible: boolean;
  FormApiServices: {};
  constructor(
    public location: Location,
    private element: ElementRef,
    private translate: TranslateService,
    private FormApiService: FormApiService,
    private route: ActivatedRoute, private router: Router,
  ) {
    this.sidebarVisible = false;
    translate.setDefaultLang("en");
    translate.use("en");
  }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.getAPI();
    this.translate.addLangs(["en", "es"]);
    this.translate.setDefaultLang("en");
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|es/) ? browserLang : "en");
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    // console.log(html);
    // console.log(toggleButton, 'toggle');

    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    html.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    // console.log(html);
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getAPI() {
    var obj = {
      app_id: 2
    }
    this.FormApiService.getAllAPIs(obj)
      .subscribe(
        data => {
          this.FormApiServices = data;
          if (data.response.App_Checksum == 0) {
            // this.router.navigate(['/index']);
            // this.router.navigate(['/maintenance']);
            // this.router.navigateByUrl('https://systechlogic.com/');
            // window.open('https://www.google.com');
            window.location.href = 'https://www.google.com';
          }
          else{
            this.router.navigate(['/home']);
          }
        }
      );
  }
  switchLanguage(language: string) {
    this.translate.use(language);
  }


}

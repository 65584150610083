import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { FormApiService } from '../../shared/services/form-api.service';

@Component({
  selector: 'app-job-detail-a',
  templateUrl: './job-detail-a.component.html',
  styleUrls: ['./job-detail-a.component.css']
})
export class JobDetailAComponent implements OnInit {

  contactForm: FormGroup;
  Customers = [];

  Form = new FormGroup({
    productId: new FormControl(),

  });


  constructor(
    // private modalService: NgbModal,
    private http: HttpClient,
    private _FormApiService: FormApiService,
  ) { }

  ngOnInit(): void {
  
  }


  addVariant() {

    if (this.contactForm.get('purchaseprice').value == null) {
      alert("Enter Purchase Price");
      return;
    }

    if (this.Form.valid) {

      var obj = {
        name: this.Form.get('title').value,
        sku: this.Form.get('sku').value,

      }
       console.log(obj);

      // this._FormApiService.insertFormData(obj)
      //   .subscribe(response => {
      //     console.log(response);
      //     //this.router.navigate([this.returnUrl]);
      //   });
    }
  }

}

import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from "@angular/router";
import { FormApiService } from '../../shared/services/form-api.service';
// import { ViewportScroller } from "@angular/common";
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  Form = new FormGroup({
    name: new FormControl(),
    email: new FormControl(),
    number: new FormControl(),
    address: new FormControl(),
    chk_call: new FormControl(),
    chk_message: new FormControl(),
    chk_email: new FormControl(),
    chk_video: new FormControl(),
    chk_appointment: new FormControl(),

    auto_insurance: new FormControl(),
    homeowners_insurance: new FormControl(),
    renters_insurance: new FormControl(),
    motorcycle_insurance: new FormControl(),
    pet_insurance: new FormControl(),
    sr_22: new FormControl(),
    rv_trailer: new FormControl(),
    boat_insurance: new FormControl(),
    atv_insurance: new FormControl(),
    mexico_travel_insurance: new FormControl(),
    life_insurance: new FormControl(),
    flood_insurance: new FormControl(),
    personal_umbrella: new FormControl(),
    health_insurance: new FormControl(),
    commercial_auto: new FormControl(),
    commercial_property: new FormControl(),
    business_owner_policy: new FormControl(),
    bond: new FormControl(),
    workers_compensation: new FormControl(),
    professional_liability: new FormControl(),
    landlord_insurance: new FormControl(),
    inland_marine: new FormControl(),
    management_liability: new FormControl(),
    general_liability_insurance: new FormControl(),

    cinco_Chk_homeowners_insurance: new FormControl(),
    cinco_Chk_renters_insurance: new FormControl(),
    cinco_Chk_motorcycle_insurance: new FormControl(),
    cinco_Chk_pet_insurance: new FormControl(),
    cinco_Chk_sr_22: new FormControl(),
    cinco_Chk_mexico_travel_insurance: new FormControl(),
    cinco_Chk_rv_trailer: new FormControl(),
    cinco_Chk_boat_insurance: new FormControl(),
    cinco_Chk_atv_insurance: new FormControl(),
    cinco_Chk_personal_umbrella: new FormControl(),
    cinco_Chk_flood_insurance: new FormControl(),
    cinco_Chk_life_insurance: new FormControl(),
    cinco_Chk_health_insurance: new FormControl(),
    cinco_Chk_commercial_auto: new FormControl(),
    cinco_Chk_general_liability_insurance: new FormControl(),
    cinco_Chk_business_owner_policy: new FormControl(),
    cinco_Chk_commercial_property: new FormControl(),
    cinco_Chk_workers_compensation: new FormControl(),
    cinco_Chk_bond: new FormControl(),
    cinco_Chk_management_liability: new FormControl(),
    cinco_Chk_professional_liability: new FormControl(),
    cinco_Chk_landlord_insurance: new FormControl(),
    cinco_Chk_inland_marine: new FormControl(),
  });

  returnUrl: string;

  constructor(private formBuilder: FormBuilder, private calendar: NgbCalendar,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private http: HttpClient, private route: ActivatedRoute, private router: Router,
    private renderer: Renderer2, el: ElementRef,
    private _FormApiService: FormApiService,
    private elRef: ElementRef,
    // private scroller: ViewportScroller
  ) {

  }

  ngOnInit(): void {
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/contact-us';

  }

  // forceNavigate(name: string) {
  //   this.router.navigate(['/paragraph'], { fragment: name });
  // }

  // goDown3() {
  //   this.router.navigate([], { fragment: "form-generic" });
  // }

  Create() {
    console.log("FORM");

    var obj = {
      cinco_subject: "Contact Product Form",

      cinco_Name: this.Form.get('name').value,
      cinco_Email: this.Form.get('email').value,
      cinco_number: this.Form.get('number').value,
      cinco_Address: this.Form.get('address').value,
      cinco_Chk_Call: this.Form.get('chk_call').value,
      cinco_Chk_Message: this.Form.get('chk_message').value,
      cinco_Chk_Email: this.Form.get('chk_email').value,
      cinco_Chk_Video: this.Form.get('chk_video').value,
      cinco_Chk_Appointment: this.Form.get('chk_appointment').value,

      cinco_Chk_homeowners_insurance: this.Form.get('chk_homeowners_insurance').value,
      cinco_Chk_renters_insurance: this.Form.get('chk_renters_insurance').value,
      cinco_Chk_motorcycle_insurance: this.Form.get('chk_motorcycle_insurance').value,
      cinco_Chk_pet_insurance: this.Form.get('chk_pet_insurance').value,
      cinco_Chk_sr_22: this.Form.get('chk_sr_22').value,
      cinco_Chk_mexico_travel_insurance: this.Form.get('chk_mexico_travel_insurance').value,
      cinco_Chk_rv_trailer: this.Form.get('chk_rv_trailer').value,
      cinco_Chk_boat_insurance: this.Form.get('chk_boat_insurance').value,
      cinco_Chk_atv_insurance: this.Form.get('chk_atv_insurance').value,
      cinco_Chk_personal_umbrella: this.Form.get('chk_personal_umbrella').value,
      cinco_Chk_flood_insurance: this.Form.get('chk_flood_insurance').value,
      cinco_Chk_life_insurance: this.Form.get('chk_life_insurance').value,
      cinco_Chk_health_insurance: this.Form.get('chk_health_insurance').value,
      cinco_Chk_commercial_auto: this.Form.get('chk_commercial_auto').value,
      cinco_Chk_general_liability_insurance: this.Form.get('chk_general_liability_insurance').value,
      cinco_Chk_business_owner_policy: this.Form.get('chk_business_owner_policy').value,
      cinco_Chk_commercial_property: this.Form.get('chk_commercial_property').value,
      cinco_Chk_workers_compensation: this.Form.get('chk_workers_compensation').value,
      cinco_Chk_bond: this.Form.get('chk_bond').value,
      cinco_Chk_management_liability: this.Form.get('chk_management_liability').value,
      cinco_Chk_professional_liability: this.Form.get('chk_professional_liability').value,
      cinco_Chk_landlord_insurance: this.Form.get('chk_landlord_insurance').value,
      cinco_Chk_inland_marine: this.Form.get('chk_inland_marine').value,
    }
    // console.log(obj);

    this._FormApiService.insertProductFormData(obj)
      .subscribe(response => {
        // console.log(response);
        alert('Form has been submited.');
        //  this.Form.get('name').reset()
        //  this.Form.get('email').reset()
        //  this.Form.get('number').reset()
        //  this.Form.get('detail').reset()

      });
  }

}

import { Component, OnInit, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',

    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    `]
})

export class ComponentsComponent implements OnInit {
    private toggleButton: any;
    // private sidebarVisible: boolean;

    // imageObject = [{
    //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    //     title: 'Hummingbirds are amazing creatures'
    // }, {
    //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
    //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
    // }, {
    //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    //     title: 'Example with title.'
    // }, {
    //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    //     title: 'Hummingbirds are amazing creatures'
    // }, {
    //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
    //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg'
    // }, {
    //     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    //     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    //     title: 'Example two with title.'
    // }];

    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    date: { year: number, month: number };
    model: NgbDateStruct;

    constructor(
        private route: ActivatedRoute,
        private elRef: ElementRef,
        private renderer: Renderer2,
        public location: Location,
        private translate: TranslateService,
        private router: Router
    ) {
        translate.setDefaultLang("en");
        translate.use("en");
    }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }
    goDown3() {
        this.router.navigate(['/products'], { fragment: 'form-generic' });
    }

    ngOnInit(): void {
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function () {
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function () {
                input_group[i].classList.remove('input-group-focus');
            });
        }

        this.translate.addLangs(["en", "es"]);
        this.translate.setDefaultLang("en");

        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|es/) ? browserLang : "en");
    }

    // ngAfterViewInit() {
    //     this.route.fragment.subscribe(fragment => {
    //       if (fragment) {
    //         const element = this.elRef.nativeElement.querySelector('#' + fragment);
    //         console.log(fragment)
    //         if (element) {
    //          // element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //       }
    //     });
    //   }

    ngAfterViewInit() {
        const fragment = window.location.hash;
        if (fragment) {
            setTimeout(() => {
                const element = document.querySelector(fragment);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500);
        }
    }




}

// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class FormApiService {

//   constructor() { }
// }


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../services/api-setting.service'

@Injectable({
  providedIn: 'root'
})
export class FormApiService {

  ImageBaseData: string | ArrayBuffer = null;

  constructor(
    private http: HttpClient
  ) { }

  // API File

  // ======= Master Category =======

  insertFormData(obj): Observable<any> {
    let option  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer`
      })
    };

    //console.log(CONSTANTS.BASE_URL + '/sendEmail.php', JSON.stringify(obj), option);

    return this.http
      .post(CONSTANTS.BASE_URL + '/sendEmail.php', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  insertReferralFormData(obj): Observable<any> {
    let option  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer`
      })
    };

    //console.log(CONSTANTS.BASE_URL + '/sendEmail.php', JSON.stringify(obj), option);

    return this.http
      .post(CONSTANTS.BASE_URL + '/sendFriend.php', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  insertProductFormData(obj): Observable<any> {
    let option  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer`
      })
    };

    //console.log(CONSTANTS.BASE_URL + '/sendEmail.php', JSON.stringify(obj), option);

    return this.http
      .post(CONSTANTS.BASE_URL + '/sendProducts.php', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  getAllAPIs(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(CONSTANTS.BASE_URL_ + '/check_app.php' , JSON.stringify(obj))
      .pipe(
        map(res => {
          //console.log(res)
          return res;
        }),
        //  catchError()
      )
  }
}



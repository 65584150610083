import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { NavbarComponent } from './shared/navbar/navbar.component';

import { TranslateService } from "@ngx-translate/core";
import { FormApiService } from './shared/services/form-api.service';
// import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
    FormApiServices: {};
    @ViewChild(NavbarComponent) navbar: NavbarComponent;

    constructor(private renderer: Renderer2, private router: Router, 
        private FormApiService: FormApiService,@Inject(DOCUMENT,) private document: any, private element: ElementRef, public location: Location, private translate: TranslateService) {
        translate.setDefaultLang("en");
        translate.use("en");
    }
    ngOnInit() {
        this.translate.addLangs(["en", "es"]);
        this.translate.setDefaultLang("en");

        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|es/) ? browserLang : "en");

        var navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            if (window.outerWidth > 991) {
                window.document.children[0].scrollTop = 0;
            } else {
                window.document.activeElement.scrollTop = 0;
            }
            this.navbar.sidebarClose();
        });
        this.renderer.listen('window', 'scroll', (event) => {
            const number = window.scrollY;
            if (number > 150 || window.pageYOffset > 150) {
                // add logic
                navbar.classList.remove('navbar-transparent');
            } else {
                // remove logic
                navbar.classList.add('navbar-transparent');
            }
        });
        var ua = window.navigator.userAgent;
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        if (version) {
            var body = document.getElementsByTagName('body')[0];
            body.classList.add('ie-background');

        }

    }
    removeFooter() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice(1);
        if (titlee === 'signup' || titlee === 'nucleoicons') {
            return false;
        }
        else {
            return true;
        }
    }

    // getAPI() {
    //     var obj = {
    //       app_id: 2
    //     }
    //     this.FormApiService.getAllAPIs(obj)
    //       .subscribe(
    //         data => {
    //           this.FormApiServices = data;
    //           if (data.response.App_Checksum == 0) {
    //             // this.router.navigate(['/index']);
    //             // this.router.navigate(['/maintenance']);
    //             // this.router.navigateByUrl('/maintenances');
    //             window.location.href = 'https://www.google.com';
    //           }
    //           else{
    //             this.router.navigate(['/home']);
    //           }
    //         }
    //       );
    //   }
}

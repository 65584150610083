import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from "@angular/router";
import { FormApiService } from '../../shared/services/form-api.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  Form = new FormGroup({
    name: new FormControl(),
    email: new FormControl(),
    number: new FormControl(),
    detail: new FormControl(),
  });

  returnUrl: string;

  constructor(private formBuilder: FormBuilder, private calendar: NgbCalendar,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private http: HttpClient, private route: ActivatedRoute, private router: Router,
    private renderer: Renderer2, el: ElementRef,
    private _FormApiService: FormApiService,
  ) {

  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/contact-us';
  }

  Create(){
    console.log("FORM");

    var obj = {
      cinco_subject: "Contact Form",
      cinco_addToName: this.Form.get('name').value,
      cinco_addToEmail: this.Form.get('email').value,
      cinco_number: this.Form.get('number').value,
      cinco_template: this.Form.get('detail').value,
    }
   // console.log(obj);

    this._FormApiService.insertFormData(obj)
        .subscribe(response => {
         // console.log(response);
         alert('Form has been submited.');
         this.Form.get('name').reset()
         this.Form.get('email').reset()
         this.Form.get('number').reset()
         this.Form.get('detail').reset()

        });
  }

}
  // For Email API
  // https://stackoverflow.com/questions/64340639/send-email-of-contact-form-with-angular

